@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2&family=Roboto+Slab:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@600&display=swap');

html, body { 
  overflow-x: hidden;
}

.mobile {
  display: none;
}
.desktop {
  display: contents;
}
a {
  text-decoration: none !important;
}
.linker {
  margin-top: -40px; position: absolute;
}
.content {
  background-color: #222529;
  padding: 5px 15%;
  z-index: -1;
}

.me-photo {
  width: 200px;
  margin: 0px 30px 0px 0px;
}

.container2 {
  background-color: #282f38;
  padding: 50px 10%;
}
.container2 h1 {
  margin-right: 40px;
}
.zindex {
  z-index: 1;
  position: relative;
}
.bgbox {
  height: 400px;
  width: 2000px;
  left: -900px;
  background-color:#282f38;
  transform: rotate(160deg);
  position: absolute;
  z-index: 0;
}
.hoverblue li h5 {
  transition: 0.2s
}

.hoverblue li h5:hover {
  color: #1ebbd7 !important;
}

/* logos animation */
.logo img.Sirv.image-hover {
height: 120px !important;
}

.logo {
  position: relative;
  margin-right: 40px;
}

.logo img.Sirv.image-main{
  height: 120px !important;
  opacity: 25%;
}

.logo img.Sirv.image-hover {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  object-fit: contain;
  opacity: 0;
  transition: 0.4s;
}

.p50px:hover .logo img.Sirv.image-hover {
  opacity: 1;
}

.logo-portfolio {
  height: 120px !important;
  opacity: 25%;
  transition: 0.2s;
}
.p50px:hover .logo img.logo-portfolio {
  opacity: 1;
}

/* blocks */
.block-title {
  height: 575px;
}

.block-info {
  border: solid 1.5px #3a3e48;
  border-radius: 16px;
  width: 300px;
  padding: 20px 15px;
  margin: 20px;
  transition: 0.2s;
}

.block-info h4 {
  transition: 0.5;
}

.block-info:hover h4{
  color: #1ebbd7;
}

.block-icon {
  height: 50px;
  transition: 0.3s;
}

.block-white {
  background-color: rgb(224, 224, 224);
  border-radius: 130px;
  margin: 50px 0px;
  padding: 15px 50px 15px 15px;
  -webkit-box-shadow: 0px 0px 44px -3px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 44px -3px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 44px -3px rgba(0,0,0,0.75);
}



.block-info:hover {
  border: solid 3px #4e515a;
}

.block-info:hover .block-icon {
  height: 65px;
}

.block-contact {
  padding: 15px;
  margin: 10px;
  border-radius: 15px;
  background-color: whitesmoke;
  border: solid 3px #868686;
  transition: 0.2s;
}
.block-contact h5 {
  opacity: 70%;
  transition: 0.2s;
}

.block-contact:hover {
  border: solid 3px #1ebbd7;
}
.block-contact:hover .contact-icon,.block-contact:hover h5 {
  opacity: 1;
}

.contact-icon {
  height: 40px;
  margin-right: 15px;
  opacity: 70%;
  transition: 0.2s;
}



.small-icon {
  height: 15px;
}

/* divisors */
.divisor {
  background-color: rgba(214, 214, 214, 0.306);
  height: 1px;
  width: 90%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.divisor2 {
  background-color: rgba(214, 214, 214, 0.094);
  height: 1px;
  width: 70%;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}

/* Paddings and margins */
.p50px{
  padding: 50px;
}

.p10px {
  padding: 10px
}

.sidepadding {
  padding: 0px 15%;
}

/* Objects Properties */
.flex {
  display: flex;
}

.jcspacebetween {
  justify-content: space-between;
}

.jcspacearound {
  justify-content: space-around;
}

.jccenter {
  justify-content: center;
}

.aicenter {
  align-items: center;
}


/* Text */

.bigfont {
  font-size: 110px;
}
.bolder {
  font-weight: bolder;
}

.nodeco {
  text-decoration: none;
}

.noselect {
  user-select: none;
}

.center {
  text-align: center;
}

.black {
  color: #222529;
}

.blue {
  color: #1ebbd7;
}

.white {
  color: #f5f5f5 ;
}
.grey {
  color: rgba(255, 255, 255, 0.651)!important;
}

/* Fonts */
.roboto {
  font-family: 'Roboto Slab', serif;
}

.exo {
  font-family: 'Exo 2', sans-serif;
}

.League {
  font-family: 'League Spartan', 'sans-serif';
}

.caveat {
  font-family: 'Caveat', cursive;
}

.mono {
  font-family: 'IBM Plex Mono', monospace;
}

/* Navbar */
.navbar-logo {
  padding: 5px 50px;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0px 50px;
  overflow: hidden;
}
nav ul li {
  float: left;
  margin: 10px;
}
nav {
  background-color: #0F3460;
  padding: 0px 30% 0px;
  -webkit-box-shadow: 0px 26px 78px -17px rgba(0,0,0,0.07);
  -moz-box-shadow: 0px 26px 78px -17px rgba(0,0,0,0.07);
  box-shadow: 0px 26px 78px -17px rgba(0,0,0,0.07); 
}

/* Footer */
.footer {
  padding: 10px 0px;
  border-top: solid 1px #4f4f4f;
}


/* App */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@media (max-width: 1000px) {
  .mobile {
    display: contents;
  }
  .block-contact h5  {
    margin-bottom: 0px !important;
  }
  .desktop {
    display: none;
  }
  .content {
    padding: 15px 5%;
  }
  .container2 {
    padding: 50px 0%;
  }
  .container2 h1 {
  margin: 0px;
  }
  .bgbox {
    display: none;
  }

  .p50px {
    padding: 5px;
  }

  /* Divisors */
  .divisor {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .divisor2 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  /* Nav */
  .navbar-title {
    font-size: 40px;
    text-align: center;
  }
  .navbar-logo h1 {
    text-align: center;
  }

  /* Blocks */
  .block-title{
    height: fit-content;
    padding-top: 20px;
  } 
  .block-contact h5 {
    font-size: 15px;
  }
  .block-contact {
     margin: 20px 10%;
    } 
  .block-white {
    padding: 15px 15px 15px 15px;
    border-radius: 20px;
    margin: 50px 5%;
  }
  .block-info {
    width: 100%;
    margin: 20px 0px;
  }
  
  /* Logos */
  .logo img.Sirv.image-main{
      max-width: 100%;
      opacity: 1;
    }
  .logo-portfolio {
    opacity: 1;
  }
  .logo {
    margin: 20px;
  }
  /* Fonts */
  .bigfont {
    font-size: 60px;
  }
  h3.grey.mono {
    text-align: center;
}
}